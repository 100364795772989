.page-detail-block {
    padding: 10px 0 20px;
}

.returns-change-year {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 30px;
}
.returns-change-year .page-item{
    min-width: 90px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 5px 0;
    box-shadow: none;
}

.returns-change-year .page-item span{
    margin-right: 5px;
    margin-left: 5px;
    cursor: pointer;
    font-weight: 500;
}

.returns-change-year .page-item span:hover, .returns-change-year .active-show {
    color: #007bff;
}

.detail-item-information .dashboard-filter-totals.lightui1-shimmer {
    box-shadow: none;
    margin-top: 20px;
}
.detail-info-table table {
    margin: auto;
    margin-left: 0;
    width: 100%;
}
.detail-info-table th{
    border: 1px solid #dee2e9;
    border-bottom-color: rgb(222, 226, 233);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: #5a6169;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    padding: 3px;
    border-bottom: 2px solid #5a6169;
    width: 7%;
}
.detail-info-table td{
    border: 1px solid #dee2e9;
    color: #818ea3;
    font-size: 14px;
    padding: 3px 5px;
    text-align: center;
}
.detail-info-table {
    margin: 20px 0 20px;
}
.display-return-switcher.returns-change-year {
    flex-direction: row;
    padding-left: 12px;
}
.row-title.row {
    margin-bottom: 20px;
}
.returns-all-per-item {
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
}
.detail-info-table.container.open-orders {margin-top: 0px;margin-left: 0;font-size: 14px;font-weight: 300;}
.detail-info table{width: 60%;}
.detail-info.container {font-size: 16px;}

.not-show-display.row {display: none;}
.show-display.row {display: flex;}
.show-icons{font-size: 20px;cursor: pointer;margin-left: 0px;width: 100px;padding-left: 0px;}
.show-chart .chart-show{color:#007bff}
.show-table .table-show{color:#007bff}
.title-icons-block{display: flex;align-items: baseline;}
.active-show-percent,.active-show-total{display: block;}
.noactive-show-percent,.noactive-show-total {display: none;}

.detail-item-information .dashboard-filter-totals.lightui1-shimmer .grid-spinner{top: -18px;}
.detail-item-information .show-display.row{justify-content: center;width: 100%;}

.table-with-sum table thead th:last-child{border-left-width: 2px;border-left-color: #5a6169;}
.table-with-sum table tbody td:last-child{border-left-width: 2px;border-left-color: #5a6169;}

.suppliers-table {margin: 30px 0 20px;}
.suppliers-table table {width: auto;font-size: 15px;}
.suppliers-table th {border-bottom: 2px solid #5a6169;padding: 0 25px 0 0;}
.suppliers-table td {padding: 3px 25px 3px 0;}

.detail-item-information {padding: 15px 25px;}
.detail-item-information h5 {color: #333;font-weight: 500;}
.detail-info .col-sm-12.container {padding: 0;}
.detail-info-block {display: flex;padding: 0;}
.detail-sku {width: 15%;font-weight: 300;font-style: italic;font-size: 19px;}
.detail-info-item-order {width: 85%;}
.detail-info-row {display: flex;}
.detail-info-row span {display: block;width: 25%;}
.detail-info-column-title {color: #333;font-size: 14px;font-weight: 700;}
.detail-info-column-value {font-size: 14px;font-weight: 300;}
.detail-info-item {margin-bottom: 20px;}

.dashboard-totals-content.table-with-sum th{color: #333;font-size: 14px;font-weight: 700;border: none;}
.dashboard-totals-content.table-with-sum td{font-size: 14px;font-weight: 300;border:none;}
.page-detail-block .detail-info-table.container{width: 100%;flex: 1;padding-right: 5%;}
.page-detail-block .row-title.row{margin-bottom: 0;}
.detail-item-information .page-detail-block h5{margin-bottom: 5px;font-size: 23px;font-weight: 500;}
.detail-table-changer-block{display: flex;width: 100%;}
.open-purchase.row {margin-top: 40px;}
.inventory-big-detail.table-grid-detail {padding: 0;margin-top: -1rem;}
.inventory-big-detail.table-grid-detail .table-grid-detail-actions {position: absolute;right: 0px;z-index: 9;top: -55px;}