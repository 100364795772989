.forgot-password{margin-left:20px;}
.nodata {background-color: #fdf0d5;padding: 10px 20px;color: #6f4400;font-size: 13px;}
.table-grid-container .table tr.nodata td{padding-left: 36px !important;padding-top: 10px !important;padding-bottom: 10px !important;background-color: #fdf0d5;}
/* Page Layout */
.main-content > .main-content-container.container-fluid{min-height:calc(100vh - 9rem)}

/* Header */
.nav-item.dropdown{cursor:pointer;}
.sidebar-logo{padding-left:30px;padding-top:5px;}

/* Login page */
.login-page{background-image:none;background-color: #fff;font-family:'Lato',sans-serif !important;}
.login-page .main-navbar{background:#fff!important;box-shadow: none;}
.login-page .login-form{max-width: 524px;max-height: 345px;height: 345px;box-shadow: 4px 4px 0px 0px rgb(0, 0, 0);border: 3px solid #000;border-radius: 0;margin: 0 auto;}
.login-page h3{color: #3a3a3a;text-transform: none;font-weight: 600;font-family: 'Montserrat', sans-serif;font-size: 39px;}
.login-page .btn-accent{border-color: #000;background-color: #fad35e;border-radius: 0;color: #3a3a3a;font-family: 'Montserrat', sans-serif;font-weight: 800;font-size: 20px;border-width: 3px;}
.login-page .btn-accent:hover{opacity:0.8;box-shadow: none;}
.login-page a{font-size:16px!important;font-weight:300!important;color:#6b6b6b;}
.login-page .form-control{font-size:20px;width: 285px;border: none;border-bottom: 3px solid #000;border-radius: 0;padding-bottom: 0;padding-top: 0;-webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;}
.login-page .login-navbar-toggle{display:none;}
.login-page .forgot-password.nav-link{display: inline-block;font-family: 'Montserrat', sans-serif;font-weight: 400 !important;font-style: italic;font-size: 20px !important;color: #3a3a3a;padding-left: 0;margin-left: 21px;}
.login-form .card-header {padding-left: 44px;padding-top: 40px;padding-bottom: 32px;}
.login-form.card-small .card-body {padding: 0 44px;}
.container-logos.container {height: 91px;display: flex;justify-content: center;margin-bottom: 31px;}
.container-logos.container .performance-logo{max-width: 91px;height: 91px;margin: 0 12px;background-image: url('../assets/images/login/top_icon1.png');width: 33%;background-size: 100%;background-repeat: no-repeat;}
.container-logos.container .digital-logo{max-width: 91px;height: 91px;margin: 0 12px;background-image: url('../assets/images/login/top_icon2.png');width: 33%;background-size: 100%;background-repeat: no-repeat;}
.container-logos.container .knowhow-logo{max-width: 91px;height: 91px;margin: 0 12px;background-image: url('../assets/images/login/top_icon3.png');width: 33%;background-size: 100%;background-repeat: no-repeat;}
.login-form label {color: #3a3a3a;font-weight: 600;font-size: 20px;font-family: 'Montserrat', sans-serif;margin: 0;line-height: 20px;}
.login-form .form-group {display: flex;justify-content: space-between;align-items: flex-end;margin-bottom: 35px;}
.login-form .quick-post-form .form-group:nth-child(2){flex: 0 1;}
.login-form .quick-post-form .px-0.container{display: flex;align-items: center;}
.login-page .form-control:focus {box-shadow: none;border-color: #000;-webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;}
.login-page .form-control:hover {border-color: #000;}
.login-navbar.p-0.row {text-align: center;}
.login-navbar.p-0.row .login-logo-container {margin: auto;margin-bottom: 50px;margin-top: 37px;}
.login-navbar.p-0.row .login-logo-container .login-logo {width: 219px;}
.login-page.main-content > .main-content-container.container-fluid {min-height: calc(100vh - 229px);}
.login-page .offset-md-3.mb-4{min-width: 524px;margin: auto;}
/* Forgot Password */
.forgot-password-page .card-small .card-body{padding-bottom:0px!important;padding-left: 44px;padding-top: 40px;}
.forgot-password-page .form-group{margin-bottom:0px!important}
.forgot-password-page .success-message button,
.forgot-password-page .success-message{width:100%}
.forgot-password-page .card-small .card-body h3 {padding: 0 !important;padding-bottom: 0px;padding-bottom: 32px !important;}
.forgot-password-page .card-footer{padding: 1rem 44px;}

/* Navigation */
.navbar-menu{text-align:right;}
.navbar-menu .nav{float:right;line-height:52px;}
.navbar-menu .nav{float:right;line-height:52px;}
.navbar-menu .nav .nav-item a{font-family:'Lato',sans-serif!important;font-size:16px!important;font-weight:300!important;color:#6b6b6b;text-transform:uppercase;}

/* CMS page */
.cms-page{margin-top:40px;margin-bottom:40px;}

/* Table Grid */
.table-grid{margin-bottom:40px;}
.table-grid tbody th{white-space:nowrap}
.table-grid tbody th i{top:6px}
.table-grid tfoot td{text-align:left;background-color:#fff}
.table-grid .sort-active{border-color:#212529}
.table-grid tfoot td strong{font-weight:bold;}
.table-grid .table-grid-pagination{float:right;}
.table-grid .table-grid-pagination a{margin-left:15px;}
.table-grid-actions{vertical-align:top;}
.table-grid-actions a .material-icons{color:#18c471;}
.table-grid-actions a.edit-icon .material-icons{color:#007bff;}
.table-grid-actions span.edit-button{cursor: pointer;}
.table-grid-actions span.edit-button .material-icons{color:#007bff;font-size: 25px;}
span.close-edit-form .material-icons{color: #007bff;font-size: 25px;padding-bottom: 10px;padding-top: 10px;cursor: pointer;}
.table-grid-actions a.delete-icon .material-icons{color:#c4183c;}
.table-grid-actions span.edit-button .material-icons.red{color:#c4183c;}
.table-grid-detail{padding:20px}
.table-grid-detail-actions{text-align:right;}
.table-grid-detail-actions a{font-size:30px;}
.table-grid-detail tbody tr td strong{text-transform:capitalize!important;}
.table-grid-actions a i.material-icons{font-size:25px;}
.table-grid-detail{}
.table-grid-detail-footer{margin-top:20px}
.bg-dark th{color:#5a6169;text-transform:uppercase;background-color:#fbfbfb;font-weight:400;font-size:13px;text-transform:none;padding:10px 15px;white-space:nowrap;border-top:1px solid #e1e5eb!important;border-bottom:1px solid #e1e5eb!important;}
.filter-row{background-color:#edf0f2;}
.filter-row .column-filter-INT64 input{width:50%;}
.filter-row input{padding:5px;width:100%;max-width:100%}
.table-grid-actions a{margin:5px;}
.table-grid-new-button{margin-bottom:20px;}
.filter-min,
.filter-max{max-width:100px;text-align:center;}
.column-filter{white-space:nowrap}
.column-filter.column-filter-datetime{width:220px;}
.column-filter.column-filter-decimal{width:120px;}
#deliveries .column-filter.column-filter-datetime{width:120px;white-space: normal;}
.column-filter .filter-min{width:48%}
.column-filter .filter-max{width:48%}
.table-grid tbody tr.filter-row{background:#fff!important}
.table-grid tbody tr:nth-child(even){background:#fff}
.table-grid tbody tr:nth-child(odd){background:#fff}
.table-grid tbody .table-grid-data td{text-overflow:ellipsis;white-space:nowrap;overflow:hidden;}
.table-grid #deliveries tbody .table-grid-data td.note-small{white-space: normal;}
.start-filter:hover,
.start-filter{text-decoration:none;margin-left:5px;color:#007bff;font-size:14px;line-height:33px;}
.clear-filters{margin-left:5px;color:#17c572;font-size:14px;border-left:1px solid #e1e5eb;padding-left:15px;margin-left:15px;}
.clear-filters.export-excel{color:#217346;}
.clear-filters:hover{text-decoration:none;color:#6c7884}
.edit-form-content,
.new-form{width:100%!important;}
.edit-form-content tr,
.new-form tr{background-color:transparent!important;}
.edit-form-content tr td,
.new-form tr td{padding:5px 0px!important;}
.edit-form-content tr td select,
.edit-form-content tr td input,
.new-form tr td select,
.new-form tr td input{padding:10px!important;width:100%}
.edit-form-content .checkboxes-options .checkboxes-options-option,
.new-form .checkboxes-options .checkboxes-options-option{display:inline-block;overflow:hidden;padding-top:10px;}
.edit-form-content .checkboxes-options .checkboxes-options-option input,
.new-form .checkboxes-options .checkboxes-options-option input{float:left;width:auto!important;margin-top:5px;}
.edit-form-content .checkboxes-options .checkboxes-options-option label,
.new-form .checkboxes-options .checkboxes-options-option label{float:left;width:auto!important;margin-right:30px;margin-left:5px}
.table-grid-detail table tr:first-child td{border-top:0px!important}
.edit-form,
.new-item-form{margin-bottom:30px}
.edit-form-buttons button{margin-right:15px;}
.edit-form-content{margin-bottom:15px;}
.edit-form-title{padding-bottom:15px;}
.csv-export{position:absolute;right:0px;z-index:9;}
.csv-export .grid-spinner{position:absolute;height:20px;width:20px;top:-20px;left:-30px;}
.page-header{position:relative}
.filter-row input.filter-from,
.filter-row input.filter-to{max-width:90px;margin-right:4px;}
.filter-row input.filter-min{margin-right:4px;}
.filter-row input.filter-min,
.filter-row input.filter-max{max-width:70px}
th.form-label{max-width:200px;width:200px;}
.password-field p{margin-bottom:10px;margin-top:5px;}
.column-filter-datetime{position:relative;}
.column-filter-datetime .react-datepicker__tab-loop{position:absolute;top:0;left:0}
.datetime-icon{margin-right:12px;}
.table-grid-data:hover td{background-color:#fff5ed}
.items-table{margin-bottom:40px;}
.items-tables{overflow:hidden;}
.items-tables-left{float:left;width:49%}
.items-tables-right{float:right;width:49%}
.column-filter .react-datepicker-wrapper{width:auto;}
.table-cell-noborder{padding:0px!important;}
.sort-arrow{font-size:20px;margin-left:6px;color:#dadfe3;top:5px;position:relative}
.sort-arrow:hover{text-decoration:none;color:#027afc}
.sort-arrow.sort-active{color:#027afc}
.orders-selector{text-transform:uppercase!important;letter-spacing:.125rem;color:#818ea3;font-weight:500;display:block;margin-bottom:15px;}
.sort-arrow-up.sort-active{transform:rotate(-180deg);-webkit-transform:rotate(-180deg);top:6px}
.page-header .react-datepicker-popper{right:0px!important;left:auto!important}
.page-header .react-datepicker__triangle{display:none!important;}
.default-filters{float:left;font-size:14px;border-right:1px solid #e1e5eb;padding-right:15px;margin-right:15px;}
.default-filters button{margin-right:15px;}
.minus-label{color:#c4183c!important}
.stock-movements-header .total-inventory-value button{height:auto!important;}
.table-grid-pagination-right{float: right;padding-top: 10px !important;padding-right: 20px;}
.limiter-header{float: right;}
/* Detail page */
.detail-content .detail-title{font-weight:600;font-size:19px;margin-bottom:20px;margin-top:10px;}
.detail-content .detail-table{overflow:hidden;margin-bottom:30px;}
.detail-content .detail-table .detail-table-primary{float:left;width:49%}
.detail-content .detail-table .detail-table-secondary{float:right;width:49%}
.detail-content .detail-table tr{border:0px!important}
.detail-content .detail-table tr:nth-child(even){background:#fff}
.detail-content .detail-table tr:nth-child(odd){background:#f1f1f1}
.detail-content .detail-table tr td,
.detail-content .detail-table tr th{border:0px!important}
.detail-content .detail-table tr th{font-weight:400;color:#676056;}
.detail-content .detail-table tr td{font-weight:400;color:#303030;}
.detail-content .detail-table{margin-bottom:20px;}
.detail-content .items-title{color:#41362f;font-weight:400;border-bottom:1px solid #ccc;margin-bottom:15px;padding:1.4rem 0 .5rem;font-size:20px}
.detail-content .items-table .item-table-secondary thead th{font-size:13px;border-top:0px;}
.detail-content .detail-table-stock{margin-bottom:25px;}
.detail-content .detail-table-title td,
.detail-content .detail-table-title th{background-color:#18c471;color:#fff!important}

/* Dashboard design */
.stats-small canvas{position:static;}
.chart-data-description{position:absolute;width:100%;padding-top:80px;}
.fetching-overlay{background-color:#fff;width:100%;height:100%;position:absolute;left:0;top:0;opacity:0.8;z-index:999}
.fetching-container{position:absolute;width:100%;height:100%;position:absolute;left:0;top:0;}
.fetching-container .grid-spinner{z-index:9999;margin:auto;position:absolute;}
.fetching-container .grid-spinner-label{z-index:9999;position:absolute;width:200px;margin-top:15px;font-size: 0;}
.fetching-container-loader{text-align:center;margin:auto;width:200px;top:50vh;position:absolute;margin-top:-35px;left:50%;margin-left:-35px;}
.bestsellers-table th{border-top:0px!important;}
.bestsellers-table tbody tr:nth-child(even){background:#fbfbfb}
.bestsellers-table tbody tr:nth-child(odd){background:#fff}

/* Inventory */
.inventory-page{}
.inventory-page .inventory-header{}
.inventory-page .inventory-header-block{padding:15px;background-color:#fff;border-radius:3px;border:1px solid #f2f2f2;}
.inventory-page .inventory-header h4{font-size:18px;font-weight:bold;color:#6c7884;}
.inventory-page .total-inventory-value button{height:50px;font-size:20px;font-weight:bold;border-radius:50px;border-width:2px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.inventory-page .top-5-products{border-bottom:1px solid #e1e5eb;border-radius:5px;overflow:hidden;}
.inventory-page .top-5-products-item{border:1px solid #e1e5eb;border-bottom:0px;font-size:13px;padding:0px!important;}
.inventory-page .top-5-products-item .top-5-products-item-qty{border-left:1px solid #e1e5eb;text-align:right;font-weight:bold;color:#17c671;}
.inventory-page .top-5-products-item .top-5-products-item-name{border-right:1px solid #e1e5eb;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.inventory-page .top-5-products-item .top-5-progress-bar{margin-top:3px;height:15px;background-color:#ebecf1;border-radius:10px;display:inline-block;width:100%;overflow:hidden}
.inventory-page .top-5-products-item .top-5-progress-bar-fill{height:15px;background: linear-gradient(90deg, rgba(0,123,255,1) 0%, rgba(0,123,255,1) 22%, rgba(23,197,114,1) 100%);border-radius:10px;display:inline-block;}
.inventory-page .total-products-item{text-align:center;}
.inventory-page .total-products-item:nth-child(1){border-right:1px solid #e1e5eb;}
.inventory-page .total-products-item:nth-child(2){border-right:1px solid #e1e5eb;}
.inventory-page .total-products{font-size:13px;padding:0px;}
.inventory-page .total-products .total-products-item .total-inventory-value{text-align:center;}
.inventory-page .total-products .total-products-item .total-inventory-value button{width:auto;margin:auto;max-width: 80%;}

/* Dashboard */
.dashboard-page .page-header{padding-bottom:0px!important;border-bottom:1px solid #e0e4eb;margin-bottom:10px;}
.dashboard-page .page-header .col-sm-12{padding:0px;}
.dashboard-page .dashboard-filter-block.dashboard-filter-block-open-shipments{background-color:#ffb400;overflow:hidden;}
.dashboard-page .dashboard-filter-block.dashboard-filter-block-shipments{background-color:#00b8d8;overflow:hidden;}
.dashboard-page .dashboard-filter-block.dashboard-filter-block-returns{background-color:#007bff;overflow:hidden;}
.dashboard-page .dashboard-filter-block.dashboard-filter-block-orders{background-color:#18c571;overflow:hidden;}
.dashboard-page .dashboard-filter-block.dashboard-filter-block-shipments .dashboard-filter-block-icon{background-color:#0083a2;}
.dashboard-page .dashboard-filter-block.dashboard-filter-block-returns .dashboard-filter-block-icon{background-color:#0050ad;}
.dashboard-page .dashboard-filter-block.dashboard-filter-block-orders .dashboard-filter-block-icon{background-color:#0f8150;}
.dashboard-page .dashboard-filter-block-icon{float:left;color:#fff;padding:10px 15px;box-sizing:border-box;}
.dashboard-page .dashboard-filter-block-icon i{font-size:30px;}
.dashboard-page .dashboard-filter-block-title{float:left;}
.dashboard-page .dashboard-filter-block-label{color:#fff;height:55px;padding:0px 15px;float:left;line-height:20px;padding-top:4px;}
.dashboard-page .dashboard-filter-block-label .dashboard-filter-block-title{display:block;float:none;text-transform:uppercase;font-weight:700;font-size:12px;}
.dashboard-page .dashboard-filter-block-label .dashboard-filter-block-value{display:block;float:none;font-size:30px;}
.dashboard-page .dashboard-filter-blocks{border-bottom:1px solid #e0e4eb;padding-bottom:25px;margin-bottom:25px;}
.dashboard-page .dashboard-filter-totals{height:375px;}
.dashboard-page .dashboard-filter-totals .dashboard-filter-totals-content{margin-bottom:50px}
.dashboard-page .dashboard-filter-totals .dashboard-filter-totals-content,
.dashboard-page .dashboard-filter-totals .dashboard-filter-map-totals-title.dashboard-filter-map-totals-title-center{text-align:center;}
.dashboard-page .dashboard-filter-totals,
.dashboard-page .dashboard-filter-map{background-color:#fff;box-sizing:border-box;padding:15px;margin-bottom:25px;height:380px;}
.dashboard-page .dashboard-filter-totals .dashboard-filter-map{margin-bottom:10px;}
.dashboard-page .dashboard-filter-map-totals-second .dashboard-filter-totals,
.dashboard-page .dashboard-filter-map-totals-second .dashboard-filter-map{height:485px;}
.dashboard-page .dashboard-filter-map.px-0{height:auto;}
.dashboard-page .dashboard-filter-map-table{max-height:300px;overflow:auto;}
.dashboard-page .dashboard-filter-map-totals-title{color:#818ea3;font-weight:600;font-size:16px;border-bottom:1px solid #dee2e9;padding-bottom:5px;margin-bottom:10px;line-height:35px;}
.dashboard-page .dashboard-filter-map table tr th{border:1px solid #dee2e9;color:#5a6169;font-size:14px;font-weight:600;text-align:center;padding:3px;border-bottom:2px solid #5a6169;}
.dashboard-page .dashboard-filter-map table tr td{border:1px solid #dee2e9;color:#818ea3;font-size:14px;padding:3px 5px;text-align:center;}
.dashboard-page .dashboard-filter-totals-content{margin-bottom:20px;}
.dashboard-page .dashboard-filter-totals-content .dashboard-filter-totals-content-label{font-size:13px;margin-bottom:3px;}
.dashboard-page .dashboard-filter-totals-content .dashboard-filter-totals-content-value{color:#818ea3;font-weight:600;text-align:right;}
.dashboard-page .dashboard-filter-map-totals{border-bottom:1px solid #dee2e9;margin-bottom:25px;}
.dashboard-page .dashboard-filter-map-totals-title-total{margin-top:0px;}
.dashboard-page .dashboard-fullfillment-status-row-progressbar span{position:absolute;margin-left:-30px;font-size:13px;font-weight:600;}
.dashboard-page .dashboard-fullfillment-row-progress{background-color:#ebecf1;border-radius:15px;width:90%;float:right;margin-bottom:15px;display:inline-block;height:25px;position:relative;overflow:hidden;}
.dashboard-page .dashboard-fullfillment-row-progress-inner{border-radius:15px;background:linear-gradient(90deg, rgba(0,123,255,1) 0%, rgba(0,123,255,1) 22%, rgba(23,197,114,1) 100%);height:25px;position:relative;}
.dashboard-page .dashboard-totals-block .dashboard-filter-map{height:280px!important;}
.dashboard-page .dashboard-totals-block .dashboard-totals-icon{text-align:center;}
.dashboard-page .dashboard-totals-block .dashboard-totals-icon i{font-size:80px;color:#18c571}
.dashboard-page .dashboard-totals-block .dashboard-totals-icon i.out-of-stock{color:#cc0033}
.dashboard-page .dashboard-totals-block .dashboard-totals-icon i.backorders{color:#007bff}
.dashboard-page .dashboard-filter-totals .btn{font-size:20px;font-weight:bold;margin-top:15px;border-width:2px;border-radius:30px;}

.dashboard-page div.width250{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;width:250px!important;display:inline-block;}
div.width200,
.dashboard-page div.width200{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;width:200px!important;display:inline-block;}
.width150,
.dashboard-page div.width150{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;width:150px!important;display:inline-block;}
.width70,
.dashboard-page div.width70{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;width:70px!important;display:inline-block;}
.dashboard-page div.width100{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;width:100px!important;display:inline-block;}
.dashboard-page div.align-left{text-align:left;}

.dashboard-page .dashboard-filter-totals{}
.dashboard-page .dashboard-filter-totals{}
.dashboard-filter-map-totals-title-link{float:right;}
.dashboard-filter-map-totals-title-link a{font-weight:400;font-size:13px;text-decoration:underline;color:#007bff;}
.dashboard-filter-map-totals-title-link a:hover{text-decoration:none;}
.dashboard-page .dashboard-filter-map table tr:hover td{background-color:#f5f6f8}
.dashboard-page{}

/* Date picker */
.react-datepicker-wrapper{width:100%}

/* Backorders */
.top-reasons-chart{border-bottom:0px!important;}


h3{color:#000;}

.main-footer .nav .nav-item a{font-family:'Lato',sans-serif!important;font-size:14px!important;font-weight:300!important;color:#6b6b6b;}
.main-footer .copyright{font-family:'Lato',sans-serif!important;font-size:14px!important;font-weight:300!important;color:#6b6b6b;padding-top:10px;}

/* Loading */
.loading{position:fixed;width:100%;height:100%;top:0;left:0;z-index:9999999;}
.loading .overlay{position:fixed;width:100%;height:100%;top:0;left:0;z-index:9999999;background-color:#fff;opacity:0.5;}
.loading .spinner{position:absolute;z-index:9999999999;left:50%;top:50%;height:100px;width:100px;margin:0px auto;-webkit-animation: rotation .6s infinite linear;-moz-animation: rotation .6s infinite linear;-o-animation: rotation .6s infinite linear;animation: rotation .6s infinite linear;border-left:6px solid rgba(0,174,239,.15);border-right:6px solid rgba(0,174,239,.15);border-bottom:6px solid rgba(0,174,239,.15);border-top:6px solid rgba(0,174,239,.8);border-radius:100%;margin-top:-50px;margin-left:-50px;}
.grid-spinner{display:block;clear:both;height:70px;width:70px;margin:0px;margin-top:30px;-webkit-animation: rotation .6s infinite linear;-moz-animation: rotation .6s infinite linear;-o-animation: rotation .6s infinite linear;animation: rotation .6s infinite linear;border-left:6px solid rgba(0,174,239,.15);border-right:6px solid rgba(0,174,239,.15);border-bottom:6px solid rgba(0,174,239,.15);border-top:6px solid rgba(0,174,239,.8);border-radius:100%;}

.dashboard-filter-block .grid-spinner{position: absolute;left: 0;right: 0;top: 15px;height: 30px;width: 30px;margin: auto;}
.dashboard-filter-totals.lightui1-shimmer {background-size: auto;background-image: none;}
.dashboard-filter-totals.lightui1-shimmer .grid-spinner{position: absolute;left: 0;right: 0;margin: auto;top: 30%;}
.chart-dashboard-block .dashboard-filter-totals.lightui1-shimmer{position: absolute;z-index: 99;width: 97%;}
.dashboard-filter-totals .dashboard-filter-totals.lightui1-shimmer{position: absolute;width: 97%;right: 0;top: 0;}
.dashboard-filter-map-block .dashboard-filter-totals.lightui1-shimmer {position: absolute;width: 97%;}

.top-title-link-backorders{display: flex;justify-content: space-between;align-items: flex-end;}
.show-all-items{font-weight: 400;font-size: 13px;text-decoration: underline;color: #007bff;padding: 0;line-height: 18px;padding-bottom: 7px;text-align: right;cursor: pointer;}
.show-all-items:hover{text-decoration: none;}
.title-top-items-backorders{padding: 0;margin: 0;}
/* Clearfix */
.clearfix{width:100%;clear:both;height:1px;}

@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
}

/* Alert window */
.react-confirm-alert-overlay{z-index:99999}

.table-grid-container{font-size:13px!important;padding:5px 0px!important}
.table-grid-container .table td{padding:5px!important;}
.table-cell-noborder{border-top:0px!important;}
.export-overlay{position:fixed;left:0;top:0;width:100%;height:100%;background-color:#fff;opacity:0.8;z-index:9999}
.export-overlay .grid-spinner{margin:auto;margin-top:100px}
.mark-as-refund i{color:#007bff!important}
.mark-as-refund:hover i{color:#18c471!important}
.refunded i{color:#18c471!important}
.column-filter.receivegoods-form{position: relative;}
.column-filter.receivegoods-form .react-datepicker__tab-loop {position: absolute;top: 0; left: 0;}
.average-dispatch{color: #17c671;font-size: 19px;}
/* Responsivity */
@media (max-width: 767px) {
	/* Login page */
	.login-page{overflow:hidden}
	.login-page .login-navigation{display:none}
	.login-page .login-logo{height:60px;}
	.login-page .login-logo-container{width:100%!important;max-width:100%!important;flex:0 0 100%!important;text-align:center;}
	.login-page .login-form{margin-top:50px;}
	.login-page footer .nav{display:none;}
	.login-page .login-navbar-toggle{display:inline;margin-right:10px;position:absolute;padding:9px 10px;background-color:transparent;background-image:none;border:1px solid #ddd;border-radius:4px;margin-left:10px;margin-top:13px;left: 10px;}
	.login-page .login-navbar-toggle .icon-bar{display:block;width:22px;height:2px;border-radius:1px;background-color:#888;margin-bottom:4px;}
	.login-page .login-navbar-toggle .icon-bar:last-child{margin-bottom:0px;}
	.login-page .login-navigation.show-navigation{display:block!important;width:100%!important;max-width:100%!important;flex:0 0 100%!important;}
	.login-page .login-navigation.show-navigation .navbar-menu .nav{float:none!important;display:block!important;text-align:left!important;line-height:30px!important;padding:0px!important;padding-bottom:30px!important;padding-left:20px!important}
	.login-page .login-navigation.show-navigation .navbar-menu .nav a{padding:0px!important}
	.login-page .offset-md-3.mb-4{min-width: inherit;max-width: 100%;flex:100%}
	.login-form .card-header {padding-left: 20px;padding-top: 25px;padding-bottom: 25px;}
	.login-page h3{font-size: 28px;}
	.login-form.card-small .card-body {padding: 0 20px;}
	.login-form label{font-size: 16px;line-height: 16px;}
	.login-page .form-control{width: 68%;font-size: 16px;line-height: 16px;}
	.login-page .forgot-password.nav-link{font-size: 16px !important;}
	.login-page .btn-accent{font-size: 16px;}
	
	.forgot-password-page .card-small .card-body{padding-left: 20px;padding-top: 25px;padding-top: 20px;}
	.forgot-password-page .card-footer {padding: 1rem 20px;}
	
	.main-content > .main-content-container.container-fluid{min-height:calc(100vh - 8rem)}
}
.lightui1-shimmer {background: #fff;background-repeat: no-repeat;background-size: 800px 104px;height: 55px;position: relative;-webkit-box-shadow: 1px 1px 8px -7px rgba(0,0,0,0.75);-moz-box-shadow: 1px 1px 8px -7px rgba(0,0,0,0.75);box-shadow: 1px 1px 8px -7px rgba(0,0,0,0.75);}
.no-display{display: none;}
.dashboard-filter-totals.service-quality .dashboard-filter-map-totals-title{color: #818ea3;font-weight: 600;font-size: 16px;border-bottom: 1px solid #dee2e9;padding-bottom: 5px;margin-bottom: 10px;line-height: 35px;}
.dashboard-filter-totals.service-quality .btn.btn-outline-success{font-size: 20px;font-weight: bold;margin-top: 15px;border-width: 2px;border-radius: 30px;}
.dashboard-filter-totals.service-quality{padding: 0 15px;text-align: center;}
.service-quality-block-page .dashboard-filter-totals.lightui1-shimmer {box-shadow: none;}

#backorder_data_skus .column-filter.column-filter-numeric {display: none;}

.return-page.inventory-page .inventory-header .return-top-counts h4{text-align: center;margin-bottom: 0;}
.return-page.inventory-page .return-top-counts .total-inventory-value button{margin: auto;}
.col-sm-12.px-2.return-top-counts.row {margin-bottom: 10px;}

/* Order flow */
.dashboard-filter-oder-flow.container-fluid {border-bottom: 1px solid #dbdfe7;padding-bottom: 27px;margin-bottom: 25px;}
.dashboard-filter-oder-flow .row {padding: 0 14px;}
.order-flow-block {padding: 0 3.5px !important;font-family: 'Montserrat', sans-serif;font-weight: 700;font-size: 12px;}
.dashboard-filter-oder-flow-value{font-size: 32px;display: block;}
.dashboard-filter-oder-flow-title {display: block;height: 27px;line-height: 1.2;}
.order-flow-block .dashboard-orders-label {height: 92px;position:relative;padding: 12px 0 16px 31px;}
.order-flow-block .dashboard-orders-label::after {
	content: "";  
	border-top: 46px solid transparent;
	border-bottom: 46px solid transparent;
	border-left: 17px solid #3498db;
	position: absolute; right: -17px; top: 0;
	z-index: 9;
} 
.order-flow-block .dashboard-orders-label::before {
	content: "";  
	border-top: 46px solid transparent;
	border-bottom: 46px solid transparent;
	border-left: 17px solid #f5f6f8;
	position: absolute; left: 0; top: 0;
}
.order-flow-block:first-child .dashboard-orders-label{
	padding: 12px 0 16px 23px;
}
.order-flow-block:first-child .dashboard-orders-label:before {
	display: none; 
}
.order-flow-block:last-child .dashboard-orders-label:after {
	display: none; 
}
.order-flow-block .dashboard-orders-label.dashboard-orders-provided::after {
	border-left: 17px solid #6ea4f5;
}
.dashboard-filter-oder-flow.order-errors-dashboard .col-sm-2{
	flex: 0 0 13.667% !important;
	max-width: 13.667% !important;
}
.dashboard-orders-provided {
    background-color: #6ea4f5;
}
.order-flow-block .dashboard-orders-label.dashboard-orders-not-provided::after {
	border-left: 17px solid #e679ca;
}
.dashboard-orders-not-provided {
    background-color: #e679ca;
}
.order-flow-block .dashboard-orders-label.dashboard-orders-imported::after {
	border-left: 17px solid #85e3e2;
}
.dashboard-orders-imported {
	background-color: #85e3e2;
}
.order-flow-block .dashboard-orders-label.dashboard-deliveries::after {
	border-left: 17px solid #82e798;
}
.dashboard-deliveries {
	background-color: #82e798;
}
.order-flow-block .dashboard-orders-label.dashboard-deliveries-with-shipping-label::after {
	border-left: 17px solid #cae673;
}
.dashboard-deliveries-with-shipping-label{
	background-color: #cae673;
}
.order-flow-block .dashboard-orders-label.dashboard-shipments-printed::after {
	border-left: 17px solid #f4bc64;
}
.dashboard-shipments-printed {
	background-color: #f4bc64;
}
.order-flow-block .dashboard-orders-label.dashboard-shipments-dispatch::after {
	border-left: 17px solid #f3d949;
}
.dashboard-shipments-dispatch {
	background-color: #f3d949;
}
.dashboard-orders-provided + .dashboard-orders-detail {
    background-color: #bed5f6;
}
.dashboard-orders-imported + .dashboard-orders-detail {
    background-color: #c8eeef;
}
.dashboard-deliveries + .dashboard-orders-detail {
    background-color: #c7f0d2;
}
.dashboard-deliveries-with-shipping-label + .dashboard-orders-detail {
    background-color: #e4f0c3;
}
.dashboard-shipments-printed + .dashboard-orders-detail {
    background-color: #f5dfbd;
}
.dashboard-shipments-dispatch + .dashboard-orders-detail {
    background-color: rgba(243, 217, 73,0.4);
}
.dashboard-orders-detail {
    padding: 12px 20px 16px 20px;
    height: 150px;
	margin-top: 7px
}
.dashboard-orders-detail-info-value {
    font-weight: 400;
    font-size: 30px;
	line-height: 1.2;
}
.dashboard-orders-detail-info-title {
    display: block;
    font-weight: 500;
	line-height: 1.2;
	margin-bottom: -3px;
}
.dashboard-orders-detail-title {
    margin-bottom: 5px;
}
.dashboard-orders-detail-info {
    margin-bottom: 8px;
}

.table-grid tbody .table-grid-data td.skus-big-row {
    text-overflow: inherit;
    white-space: normal;
    overflow: visible;
}
.table-grid tbody .table-grid-data td.skus-big-row div.width200 {
    white-space: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px !important;
    display: inline-block;
}
.page-header .react-datepicker-popper{z-index: 10;}
.dashboard-filter-oder-flow .dashboard-orders-detail-info .dashboard-filter-block .grid-spinner{height: 25px;width: 25px;}
.dashboard-filter-oder-flow .dashboard-orders-detail-info .lightui1-shimmer{background: none;box-shadow: none;height: 25px;}
.dashboard-orders-detail {position: relative;}
.dashboard-orders-detail .block-link,.dashboard-filter-block .block-link {position: absolute;width: 100%;height: 100%;left: 0;top: 0;}
/* Avg carrier report */
.dashboard-filter-totals.service-quality .carrier-avg .carrier-avg-title {font-size: 17px;}
.dashboard-filter-totals.service-quality .carrier-avg .btn.btn-outline-success {margin-top: 5px;}
.dashboard-filter-totals.service-quality .carrier-avg {margin-bottom: 20px;}
.dashboard-page .dashboard-filter-map-totals-second .dashboard-filter-map {height: auto;}
button.active-filter.btn.btn-success {opacity: 1;border: 2px solid !important;font-weight: 500;}
.dashboard-orders-wrapper .dashboard-filter-block.lightui1-shimmer {
    background-color: rgba(0,0,0,0) !important;
    box-shadow: none;
}
.year-switcher {display: flex;align-items: center;}
.switcher-custom {display: flex;}
.year-switcher span {font-size: 22px;line-height: 25px;display: inline-block;padding: 3px;cursor: pointer;}
.switcher-custom-title.col {display: flex;align-items: center;width: 100%;max-width: 110px;flex: 100%;}
.carrier-crosstable th{border: 1px solid #dee2e9;border-bottom-color: rgb(222, 226, 233);border-bottom-style: solid;border-bottom-width: 1px;color: #5a6169;font-size: 14px;font-weight: 600;text-align: center;padding: 3px;border-bottom: 2px solid #5a6169;}
.carrier-crosstable tr td {border: 1px solid #dee2e9;color: #818ea3;font-size: 14px;padding: 3px 5px;text-align: center;}
.carrier-crosstable td:last-child, .carrier-crosstable th:last-child {border-left: 2px solid #5a6169;}
.carrier-crosstable th.avg-table,.carrier-crosstable tr td.avg-table {border-left: 2px solid #5a6169;}
.revenue-reports .carrier-crosstable td:last-child, .revenue-reports .carrier-crosstable th:last-child {border-left: 1px solid #5a6169;}
.qty-percent-switcher.container {display: flex;align-items: center;padding-left: 0;padding-bottom: 20px;}
.qty-percent-switcher-title {padding-right: 10px;}
.table tbody + tbody{border-top: 1px solid #dee2e6;}
.table tfoot{border-top: 1px solid #dee2e6;}

.average-dispatch-block button,.return-top-counts .btn.btn-outline-danger,.return-top-counts .btn.btn-outline-warning,.return-top-counts .btn.btn-outline-success{cursor: auto !important;}
.average-dispatch-block button:hover,.average-dispatch-block button:focus,.average-dispatch-block button:active,.average-dispatch-block button:not(:disabled):not(.disabled):active{color: #17c671;background-color: #fff;border-color: #17c671;box-shadow: none !important;}

.return-top-counts .btn.btn-outline-danger:hover,.return-top-counts .btn.btn-outline-danger:focus,.return-top-counts .btn.btn-outline-danger:active,.return-top-counts .btn.btn-outline-danger:active:not(:disabled):not(.disabled):active{color: #c4183c;background-color: #fff;border-color: #c4183c;box-shadow: none !important;}
.return-top-counts .btn.btn-outline-warning:hover,.return-top-counts .btn.btn-outline-warning:focus,.return-top-counts .btn.btn-outline-warning:active,.return-top-counts .btn.btn-outline-warning:active:not(:disabled):not(.disabled):active{color: #ffb400;background-color: #fff;border-color: #ffb400;box-shadow: none !important;}
.return-top-counts .btn.btn-outline-success:hover,.return-top-counts .btn.btn-outline-success:focus,.return-top-counts .btn.btn-outline-success:active,.return-top-counts .btn.btn-outline-success:active:not(:disabled):not(.disabled):active{color: #17c671;background-color: #fff;border-color: #17c671;box-shadow: none !important;}

.success-message-comments{padding-left: 5px; color: #18c471;font-size: 20px;}
.comment-info{display: flex;flex-direction: row; justify-content: space-between;text-align: left;}
.flex {display: flex;}
.items-center{align-items: center;}

.carrier-crosstable tr.total-bottom td{border-top-width: 2px;border-top-color: #333;}

.row-info-history span {display: block;padding-left: 35px;}
.row-info-history {margin-bottom: 15px;position: relative;}
.row-info-history .material-icons {position: absolute;left: 0;padding: 0;top: -1px;}

.custom-popup{position: fixed;z-index: 999999;width: 100vw;height: 100vh;top: 0;left: 0;background-color: rgba(0,0,0,0.3);}
.popup-content{position: absolute;background-color: #fff;padding: 40px 60px;left: 0;right: 0;margin: auto;width: 500px;top: 35%;}
.popup-content div {border-bottom: 1px solid #ccc;padding: 20px 0;}
.popup-content div span {display: block;}
.popup-close {border: none !important;position: absolute;top: 0;right: 20px;cursor: pointer;}
.popup-title{font-size: 17px;}
.popup-content div:last-child {border: none;}
.carrier-crosstable tr.totals-row td{background-color: rgba(88,88,88,0.1);}
.mandant-selector{min-width: 135px;}
#deliveries .sort-arrow.material-icons.sort-arrow-up[data-column="tracking_url"] {display: none;}
.last-update-info{font-size: 0.75rem;font-weight: 400;min-width: 170px;padding-left: 10px;display: flex;flex-direction: column;justify-content: center;}
.revenue-reports.revenue-reports-detail .carrier-crosstable tfoot td:last-child{border: none;}
.revenue-reports.revenue-reports-detail .carrier-crosstable tfoot td:last-child div{float: left;}
.revenue-reports.revenue-reports-detail .carrier-crosstable tfoot td:last-child div.table-grid-pagination-right{float: right;}
.carrier-crosstable .search-icon,.pointer{cursor: pointer;}
.carrier-crosstable .search-icon:hover,.carrier-crosstable tr.totals-row:hover td.pointer,.carrier-crosstable tr.totals-row td.pointer:hover {background-color: rgba(88,88,88,0.2);}
.carrier-crosstable .search-icon .material-icons {font-size: 25px; color: #18c471;}

body.modal-open aside.main-sidebar{-webkit-transform: translateX(0%);transform: translateX(0%);}
.dashboard-filter-map .chartjs-render-monitor{height: 300px !important;width: 100% !important;}
.time-of-goods-report.chartjs-render-monitor, .service-returns-report .chartjs-render-monitor,.sevice-carrier-report .chartjs-render-monitor,.service-quality-report .chartjs-render-monitor{height: 300px !important;width: 100% !important;}
.special-month-date-picker{margin: 10px 0;align-items: center;}
.special-month-date-picker-picker{margin-left: 20px;}
.table-grid tbody .table-grid-data td.note-small-width {width: 200px;white-space: normal;}
.dashboard-orders-detail{height: auto;min-height: 161px;}
.dashboard-orders-detail.dashboard-orders-detail-no-label-no-delivery{height: auto;display: flex;min-height: 150px;flex-wrap: wrap;justify-content: space-between;}

@media (max-width: 767px) {
	.last-update-info{display: none;}
	.mandant-selector {max-width: 165px;}
	.dashboard-page .page-header{display: block;}
	.col-sm-2.px-2.order-flow-block.container-fluid{margin-bottom: 10px;}
	.dashboard-orders-detail {margin-top: 0;}
	div.width200, .dashboard-page div.width200{width: 125px !important;}
	.states-map-dashboard{display: none;}
	.dashboard-page .dashboard-totals-block .dashboard-filter-map {height: auto !important;}
	.dashboard-totals-content{overflow-x: auto;}
	.chartjs-render-monitor{height: 50vh !important;width: 80vw !important;}
	.orders-table, .carrier-crosstable,.table-grid{overflow-x: scroll;}
	.orders-table .table-grid{overflow-x: hidden;}
	.carrier-graph .chartjs-render-monitor{height: 106vh !important;}
	.sevice-carrier-report .service-quality-block-page .row,.service-quality-report .service-quality-block-page .row {display: block;}
	.sevice-carrier-report .service-quality-block-page .row .col-sm-9,.service-quality-report .service-quality-block-page .row .col-sm-9{max-width: 100%;}
	.sevice-carrier-report .service-quality-block-page .row .col-sm-3,.service-quality-report .service-quality-block-page .row .col-sm-3{max-width: 100%;}
	/* Order Flow */
	.dashboard-filter-oder-flow .col-sm-2.px-2.order-flow-block.container-fluid{padding: 0 !important;display: flex;gap: 5px;margin-bottom: 5px;flex: 100%;max-width: 100%;}
	.order-flow-block .dashboard-orders-label{width: 118px;padding: 12px 0 16px 18px;min-width: 118px;}
	.dashboard-orders-detail{height: 92px; flex: 2;padding: 12px 0px 10px 28px;letter-spacing: -0.2px;}
	.dashboard-orders-detail-info-wrapper {display: flex;}
	.dashboard-filter-block span {text-align: center;color: #000;}
	.dashboard-orders-detail-info-value,.dashboard-filter-oder-flow-value{font-size: 28px;}
	.dashboard-orders-detail::before {content: "";border-top: 46px solid transparent;border-bottom: 46px solid transparent;border-left: 17px solid #f5f6f8;position: absolute;left: 0;top: 0;}
	.order-flow-block:last-child .dashboard-orders-detail::before,.order-flow-block:last-child .dashboard-orders-label:after {display: none; }
	.dashboard-orders-detail-info{min-width: 60px;width: 100%;}
	.dashboard-orders-detail-info:first-child {max-width: 125px;width: 100%;}
	.dashboard-orders-wrapper {color: #000;}
	.dashboard-orders-detail-info-title{font-size: 11px;}
	.dashboard-filter-oder-flow-title{letter-spacing: -0.2px;}
	.dashboard-orders-detail-title {margin-bottom: 0;}
	.dashboard-filter-oder-flow.container-fluid{padding-left: 0 !important;padding-right: 0 !important;padding-bottom: 10px;max-width: 420px;}
	.order-flow-block:first-child .dashboard-orders-label{padding: 12px 0 16px 13px;}
	.dashboard-orders-detail-info-value {margin-top: 13px;display: block;}
	.dashboard-filter-block span.dashboard-filter-oder-flow-value {line-height: 28px;margin-top: 17px;}
	.dashboard-filter-oder-flow .row{flex-direction: column;}
}